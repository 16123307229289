<template>
  <a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
</template>

<script setup>
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import locale from "ant-design-vue/es/locale/zh_CN";
import { onMounted } from "vue";
// import useWeChatSdk from "@/hooks/useWeChatSdk";
import { addPageBrowserRecord } from "@/api/user";
// weixin sdk
// useWeChatSdk();
// 前端按钮数据埋点记录
dayjs.locale("zh-cn");

onMounted(() => {
  document.addEventListener("click", function (e) {
    // 需要注意只向上查找一层
    const targetEle = e.target;
    const targetParent = targetEle.parentNode;
    if (
      targetEle.hasAttribute("data-monitor") ||
      (targetParent && targetParent.hasAttribute("data-monitor"))
    ) {
      addPageBrowserRecord({
        linkKey:
          targetEle.getAttribute("data-monitor") ||
          targetParent.getAttribute("data-monitor"),
        linkUrl: location.href,
      });
    }
  });
});
</script>

<style></style>
