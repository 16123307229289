import axios from "axios";
import { Notify } from "vant";
import store from "@/store";
import { message } from "ant-design-vue";
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: "/api",
  // baseURL: "/meeting-api",
  timeout: 60000, // 请求超时时间
  headers: {
    "Cache-Control": "no-cache",
  },
});
// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      if (store.state.app.isMobile) {
        Notify({ type: "danger", message: "未授权请重新登录" });
      } else {
        message.error("未授权请重新登录");
        // 清空用户信息
        store.commit("user/setUserInfo", null);
      }
    } else {
      if (store.state.app.isMobile) {
        Notify({ type: "danger", message: error.response.status });
      } else {
        message.error(error.response.status);
      }
    }
    // 清除本地存储 token
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  return Promise.reject(error);
};

// request interceptor
request.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
}, errorHandler);

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data.code === 0) {
    if (response.data.message) {
      if (store.state.app.isMobile) {
        Notify({ type: "danger", message: response.data.message });
      } else {
        message.error(response.data.message);
      }
    }
    return Promise.reject(response.data);
  } else if (response.data.code === 3 || response.data.code === 4) {
    if (store.state.app.isMobile) {
      if (response.data.message) {
        Notify({ type: "danger", message: response.data.message });
      }
    } else {
      message.error(response.data.message);
      // 清空用户信息
      store.commit("user/setUserInfo", null);
      // 清除本地存储 token
    }
    localStorage.clear();
    setTimeout(() => {
      window.location.reload();
    }, 1000);
    return Promise.reject(response.data);
  }
  return response.data;
}, errorHandler);

export default request;
