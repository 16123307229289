import { tokenGetCode, getPlatformConfig } from "@/api/user";

const personalUrl = process.env.VUE_APP_PERSONAL_URL;
const erkeUrl = process.env.VUE_APP_ERKE_URL;
const nerveUrl = process.env.VUE_APP_NERVE_URL;

// 获取来源
export const getBigOrigin = () => {
  return process.env.VUE_APP_SOURCE;
};

// 屏幕宽度
export const getScreenWidth = () => {
  return document.documentElement.clientWidth;
};

// 是微信
export const isWechatBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("micromessenger") != -1;
};

// 设置标题
export const setTitle = (title) => {
  document.title = title;
  var i = document.createElement("iframe");
  i.style.display = "none";
  i.onload = function () {
    setTimeout(function () {
      i.remove();
    }, 9);
  };
  document.body.appendChild(i);
};

// 去儿科,个人中心
export const toMyCenter = async (id) => {
  const { results } = await tokenGetCode();
  let _back_url = encodeURIComponent(location.href);
  let url = "";
  if (id == 1) {
    //儿科首页
    url = `${erkeUrl}/index?_type=index&_code=${results}&_back_url=${_back_url}`;
  } else if (id == 2) {
    //儿科课程
    url = `${erkeUrl}/learn&_code=${results}&_back_url=${_back_url}`;
  } else if (id == 3) {
    //个人中心
    if (getScreenWidth() < 1200) {
      url = `${personalUrl}/auth?_type=my&_big_origin=${getBigOrigin()}&_code=${results}&_back_url=${_back_url}`;
    } else {
      url = `${personalUrl}/auth?_type=index&_big_origin=${getBigOrigin()}&_code=${results}&_back_url=${_back_url}`;
    }
  } else if (id == 4) {
    //脑健康
    url = `${nerveUrl}?_code=${results}&_back_url=${_back_url}`;
  }
  if (getScreenWidth() < 1200) {
    location.assign(url);
  } else {
    window.open(url, "_blank");
  }
};

// 去登录
export const toCenterLogin = () => {
  let _back_url = encodeURIComponent(location.href);
  const url = `${personalUrl}/auth?_type=login&_big_origin=${getBigOrigin()}&_return_url=${_back_url}`;
  if (getScreenWidth() < 1200) {
    location.assign(url);
  } else {
    location.replace(url);
  }
};

// 去微信授权
export const toWxLogin = () => {
  let _back_url = encodeURIComponent(location.href);
  const url = `${personalUrl}/auth?_type=wxlogin&_big_origin=${getBigOrigin()}&_return_url=${_back_url}`;
  location.assign(url);
};

// 去绑定手机
export const toBindPhone = () => {
  let _back_url = encodeURIComponent(location.href);
  const url = `${personalUrl}/auth?_type=phone&_big_origin=${getBigOrigin()}&_return_url=${_back_url}`;
  if (getScreenWidth() < 1200) {
    location.assign(url);
  } else {
    window.open(url);
  }
};

// 	去play页面
export const toNewPlay = async (id) => {
  const { results } = await tokenGetCode();
  let _back_url = encodeURIComponent(location.href);
  const url = `${personalUrl}/auth?_type=play&_big_origin=${getBigOrigin()}&_code=${results}&id=${id}&_back_url=${_back_url}`;
  if (getScreenWidth() < 1200) {
    location.assign(url);
  } else {
    window.open(url, "_blank");
  }
};

// 	去ai页面
export const toAiCourse = async (id) => {
  const { results } = await tokenGetCode();
  let _back_url = encodeURIComponent(location.href);
  const url = `${personalUrl}/auth?_type=aicourse&_big_origin=${getBigOrigin()}&_code=${results}&id=${id}&_back_url=${_back_url}`;
  if (getScreenWidth() < 1200) {
    location.assign(url);
  } else {
    window.open(url, "_blank");
  }
};

// 	去习题
export const toXiTi = async (id) => {
  const { results } = await tokenGetCode();
  let _back_url = encodeURIComponent(location.href);
  const url = `${personalUrl}/auth?_type=xiti&_big_origin=${getBigOrigin()}&_code=${results}&courseId=${id}&_back_url=${_back_url}`;
  if (getScreenWidth() < 1200) {
    location.assign(url);
  } else {
    window.open(url, "_blank");
  }
};

// 去认证
export const toRenZheng = async () => {
  const { results } = await tokenGetCode();
  let _back_url = encodeURIComponent(location.href);
  const url = `${personalUrl}/auth?_type=renzheng&_big_origin=${getBigOrigin()}&_code=${results}&_back_url=${_back_url}`;
  if (getScreenWidth() < 1200) {
    location.assign(url);
  } else {
    window.open(url, "_blank");
  }
};

// 去专家中心
export const toZhuanJia = async (id) => {
  const { results: code } = await tokenGetCode();
  let _back_url = encodeURIComponent(location.href);
  const url = `${process.env.VUE_APP_EXPERT_H5}/pages/expertIndex/detail?_code=${code}&id=${id}&_back_url=${_back_url}`;
  if (getScreenWidth() < 1200) {
    location.assign(url);
  } else {
    window.open(
      `${
        process.env.VUE_APP_EXPERT_PC
      }/auth?type=expertDetail&code=${code}&userId=${id}&origin=${getBigOrigin()}`,
      "_blank"
    );
  }
};

// 根据来源获取图片
// 脑健康 医邻儿科 精准医学 卒中学院
export const getHeaderImg = async (
  origin = sessionStorage.getItem("_big_origin")
) => {
  if (origin && origin.startsWith("platform_id_")) {
    // 获取平台id
    const id = origin.slice(12);
    const { results } = await getPlatformConfig({ platformId: id });

    return [results.platformName, results.platformLogo];
  }

  let _imgPath = "yl";
  if (["nerve", "ms", "pm", "stroke"].includes(origin)) {
    _imgPath = origin;
  }

  return import(`@/assets/PC/logo/${_imgPath}.png`);
};
