import { createRouter, createWebHistory } from "vue-router";
import { pcRoutes, h5Routes } from "./routes";
import store from "@/store";
import {
  isWechatBrowser,
  setTitle,
  toCenterLogin,
  toWxLogin,
} from "@/utils/help";
import { addPageBrowserRecord, codeExchangeToken } from "@/api/user";

// 根据屏幕宽度来加载对应的路由
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: store.state.app.isMobile ? h5Routes : pcRoutes,
});

let whiteLists = ["Auth"];

router.beforeEach(async (to, from, next) => {
  // localStorage.setItem("token", "2f2ee0e0-1b7f-453e-a07d-167a99ab5232");

  let { _code } = to.query;

  if (_code) {
    try {
      let { results: newToken } = await codeExchangeToken({ code: _code });
      if (newToken) {
        newToken = newToken.split(" ")[1];
        localStorage.setItem("token", newToken);
      } else {
        // 清除本地存储 token
        localStorage.clear();
      }
    } catch (error) {
      // 清除本地存储 token
      localStorage.clear();
    }
    delete to.query._code;
    return next({ path: to.path, query: to.query, replace: true });
  }

  const token = localStorage.getItem("token");

  // 是否是微信浏览器
  const _isWechatBrowser = isWechatBrowser();

  // 有token
  if (token) {
    const userInfo = store.getters["user/getUserInfo"];
    // 当去首页，或者没有用户信息的时候 重新获取用户信息
    if (!userInfo && to.path !== "/auth") {
      try {
        await store.dispatch("user/getUserInfoAction");
        next();
      } catch (error) {
        next();
      }
    } else {
      next();
    }
    // 没token
  } else {
    // 是白名单直接进
    if (whiteLists.includes(to.name)) {
      next();
    } else {
      // 微信浏览器
      if (_isWechatBrowser) {
        toWxLogin();
        // 不是微信浏览器
      } else {
        // 去登录页面
        toCenterLogin();
      }
    }
  }
});

// 在组件beforeCreate之前执行
router.afterEach((to) => {
  // 设置页面title
  if (to.meta?.title) {
    setTitle(to.meta.title);
  } else {
    setTitle(process.env.VUE_APP_TITLE);
  }

  // 是否记录访问数据
  const tokens = localStorage.getItem("token") || "";
  if (to.meta?.record && tokens) {
    addHistory(to.meta?.record, location.href);
  }
});

// 记录浏览器参数
function addHistory(linkKey, linkUrl) {
  let params = {
    linkKey,
    linkUrl,
  };
  addPageBrowserRecord(params);
}

export default router;
