import { getUserInfo, logout } from "@/api/user";

export default {
  namespaced: true,
  state: {
    userinfo: null,
  },
  getters: {
    getterUserInfo: (state) => state.userinfo,
  },
  mutations: {
    setUserInfo(state, payload) {
      state.userinfo = payload;
    },
  },
  actions: {
    // 登出
    Logout() {
      const clear = () => {
        // 清除本地存储 token
        localStorage.clear();
        sessionStorage.clear();
      };
      return new Promise((resolve) => {
        logout({ source: "PC-WEB端" })
          .then(() => {
            clear();
            resolve();
          })
          .catch(() => {
            clear();
            resolve();
          });
      });
    },
    async getUserInfoAction({ commit }) {
      try {
        const { results } = await getUserInfo();
        // 0-项目认证，1-源默认证，2-会员，3-游客
        commit("setUserInfo", results);
        return Promise.resolve(results);
      } catch (error) {
        console.error(error);
        return Promise.reject();
      }
    },
  },
};
