<template>
  <div class="head-wrap flex-j-c">
    <div class="head-body flex-j-b flex-a-c auto-box">
      <div class="left flex-a-c">
        <img v-if="headImg" :src="headImg" alt="logo" class="logo" />
        <span class="title">{{ headTitle }}</span>
      </div>
      <div class="right m-l-80">
        <div v-if="user" class="flex-a-c">
          <a-avatar
            class="m-r-10"
            :src="
              user.imageUrl
                ? user.imageUrl
                : 'https://ntppc.oss-cn-shanghai.aliyuncs.com/avatar/default.png'
            "
          />
          <a-dropdown>
            <span class="pointer name e-l-1">{{
              user.name ? user.name : user.phone
            }}</span>
            <template #overlay>
              <a-menu @click="handleDropDownClick">
                <a-menu-item key="logout">
                  <span>退出登录</span>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <div v-else>
          <span class="pointer" @click="goLogin">登录</span> /
          <span class="pointer" @clikc="goRegister">注册</span>
        </div>
      </div>
    </div>
    <!-- 修改手机号 -->
  </div>
</template>
<script>
import { getHeaderImg, toCenterLogin } from "@/utils/help";
import store from "@/store";
export default {
  created() {
    // 初始化header
    this._initHeaderImg();
  },
  data() {
    return {
      headImg: "",
      headTitle: "",
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      // visible: false,

      isTag: process.env.VUE_APP_MODE == "tag",
      isBxb: process.env.VUE_APP_MODE == "bxb",
    };
  },
  computed: {
    user() {
      return store.getters["user/getterUserInfo"];
    },
  },
  methods: {
    async _initHeaderImg() {
      const imgPendding = await getHeaderImg();
      if (Array.isArray(imgPendding)) {
        this.headTitle = imgPendding[0];
        this.headImg = imgPendding[1];
      } else {
        this.headImg = imgPendding.default;
      }
    },
    toLogin(_return_url) {
      return `${
        process.env.VUE_APP_PERSONAL_URL
      }/auth?_type=login&_big_origin=${
        process.env.VUE_APP_SOURCE
      }&_return_url=${encodeURIComponent(_return_url)}`;
    },
    goLogin() {
      let _return_url = `${location.origin}/`;
      window.location.href = this.toLogin(_return_url);
    },
    goRegister() {
      let _return_url = `${location.origin}/`;
      window.location.href = this.toLogin(_return_url);
    },
    async handleDropDownClick({ key }) {
      switch (key) {
        case "logout":
          await store.dispatch("user/Logout");
          toCenterLogin();
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.head-wrap {
  user-select: none;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
  height: 80px;
  min-height: 80px;
  .head-body {
    height: 80px;
  }
  .left {
    height: 65px;
    flex-grow: 1;
    .logo {
      height: 36px;
      margin-right: 12px;
    }
    .title {
      font-weight: 600;
      font-size: 15px;
      color: #3c3d3f;
    }
    .menu {
      line-height: 65px;
      .menu-item {
        margin: 0 10px;
        cursor: pointer;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
      .menu-item.active {
        font-weight: 800;
        color: #6f9fff;
      }
    }
    .mhlp-logo {
      width: 45px;
      height: 45px;
    }
    .logo {
      display: flex;
      align-items: center;
      .ml12 {
        margin-left: 12px;
      }
    }
  }
  .right {
    .name {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      display: inline-block;
      max-width: 80px;
    }
  }
}
.expert-box {
  .item {
    padding: 24px 18px;
    .title {
      font-size: 14px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: center;
      padding: 4px 0;

      &::before {
        content: " ";
        display: inline-block;
        margin-right: 8px;
        width: 3px;
        height: 14px;
        // background-color: @primary-color;
      }
    }
    .name-box {
      display: grid;
      grid-template-columns: 80px 80px 80px;
      // grid-template-rows: 24px 24px 24px;
      .name {
        font-size: 14px;
        padding: 2px 0;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        &:hover {
          // color: @primary-color;
        }
      }
    }
  }
  .line {
    margin: 0 18px;
    height: 1px;
    background: #e8e8e8;
  }
  .sub-link {
    color: rgba(0, 0, 0, 0.85);
    &:hover {
      // color: @primary-color;
    }
  }
}
</style>
