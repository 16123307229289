import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import VConsole from "vconsole";
import "./style/global.less";
import "./style/carousel.css";

createApp(App).use(store).use(router).use(Antd).use(Vant).mount("#app");

if (process.env.NODE_ENV !== "production") {
  new VConsole();
}
