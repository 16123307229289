export default {
  namespaced: true,
  state: {
    isMobile: window.document.body.clientWidth - 1 < 1200, // 暂时设定小于1200为手机端
  },
  getters: {
    getIsMobile: (state) => state.isMobile,
  },
  mutations: {},
  actions: {},
};
