import Basic from "@/layout/basic.vue";
export const pcRoutes = [
  {
    path: "/",
    name: "Basic",
    component: Basic,
    redirect: "/index",
    children: [
      {
        path: "index",
        name: "Index",
        meta: { title: "" },
        component: () => import("../views/PC/ExpertList.vue"),
      },
      {
        path: "applyfor",
        name: "ApplyFor",
        meta: { title: "" },
        component: () => import("../views/PC/ApplyFor.vue"),
      },
      {
        path: "detail",
        name: "Detail",
        meta: { title: "" },
        component: () => import("../views/PC/ExpertDetail.vue"),
      },
      {
        path: "caselist",
        name: "CaseList",
        meta: { title: "" },
        component: () => import("../views/PC/CaseList.vue"),
      },
      {
        path: "/casereading",
        name: "CaseReading",
        meta: { title: "" },
        component: () => import("../views/PC/CaseReading.vue"),
      },
      {
        path: "/speciallist",
        name: "SpecialList",
        meta: { title: "" },
        component: () => import("../views/PC/SpecialList.vue"),
      },
      {
        path: "/commendslist",
        name: "CommendsList",
        meta: { title: "" },
        component: () => import("../views/PC/CommendsList.vue"),
      },
      {
        path: "/consult",
        name: "Consult",
        meta: { title: "" },
        component: () => import("../views/PC/Consult.vue"),
      },
      {
        path: "/questionlist",
        name: "QuestionList",
        meta: { title: "" },
        component: () => import("../views/PC/QuestionList.vue"),
      },
      {
        path: "/questiondetail",
        name: "QuestionDetail",
        meta: { title: "" },
        component: () => import("../views/PC/QuestionDetail.vue"),
      },
      {
        path: "/league",
        name: "League",
        meta: { title: "" },
        component: () => import("../views/PC/League.vue"),
      },
    ],
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/Error.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "404Error",
    component: () => import("../views/404View.vue"),
  },
];
export const h5Routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "Index",
    meta: { title: "" },
    component: () => import("../views/H5/ExpertList.vue"),
  },
  {
    path: "/detail",
    name: "Detail",
    meta: { title: "" },
    component: () => import("../views/H5/ExpertDetail.vue"),
  },
  {
    path: "/casereading",
    name: "CaseReading",
    meta: { title: "" },
    component: () => import("../views/H5/CaseReading.vue"),
  },
  {
    path: "/consult",
    name: "Consult",
    meta: { title: "" },
    component: () => import("../views/H5/Consult.vue"),
  },
  {
    path: "/leaguelist",
    name: "LeagueList",
    meta: { title: "" },
    component: () => import("../views/H5/LeagueList.vue"),
  },
  {
    path: "/questionlist",
    name: "QuestionList",
    meta: { title: "" },
    component: () => import("../views/H5/QuestionList.vue"),
  },
  {
    path: "/questiondetail",
    name: "QuestionDetail",
    meta: { title: "" },
    component: () => import("../views/H5/QuestionDetail.vue"),
  },
  {
    path: "/league",
    name: "League",
    meta: { title: "" },
    component: () => import("../views/H5/League.vue"),
  },
  {
    path: "/speciallist",
    name: "SpecialList",
    meta: { title: "" },
    component: () => import("../views/H5/SpecialList.vue"),
  },
  {
    path: "/caselist",
    name: "CaseList",
    meta: { title: "" },
    component: () => import("../views/H5/CaseList.vue"),
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/Error.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "404Error",
    component: () => import("../views/404View.vue"),
  },
];
