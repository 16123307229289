import request from "./request";

// 生成一次性CODE绑定TOKEN
export const tokenGetCode = (params) => {
  return request({
    url: `/user/generate/code`,
    params,
  });
};

// 消费CODE换取TOKEN
export const codeExchangeToken = (params) => {
  return request({
    url: `/user/codeExchangeToken`,
    params,
  });
};

// 添加用户网页浏览记录
export const addPageBrowserRecord = (data) => {
  return request({
    url: "/user/addPageBrowserRecord",
    method: "post",
    data,
  });
};

// 配置的YM_PM 测试环境匹配源墨科技，正式环境匹配 精准医学
export const getWechatConfig = (params) => {
  return request({
    url: "/weChat/noncestr/timestamp/bySource",
    params,
  });
};

// 获取用户信息
export const getUserInfo = () => {
  return request({
    url: "/user/getUserInfo",
  });
};

// 获取主题信息
export const getPreviewDetail = (params) => {
  return request({
    url: `/perTheme/previewDetail/${params.id}`,
  });
};

// 获取主题是否订阅信息
export const subscribeQuery = (params) => {
  return request({
    url: `/collection/subscribeQuery`,
    params,
  });
};

// 订阅主题
export const subscribe = (data) => {
  return request({
    url: "/collection/subscribe",
    data,
    method: "post",
  });
};

// 取消订阅主题
export const cancelSubscribe = (data) => {
  return request({
    url: "/collection/cancelSubscribe",
    data,
    method: "post",
  });
};

// 获取广告
export const queryAdvertOperationPushAll = (params) => {
  return request({
    url: `/advertPush/queryAdvertOperationPushAll`,
    params,
  });
};

// 记录点击广告
export const addGgUserInfo = (data) => {
  return request({
    url: "/advertPushUser/add",
    data,
    method: "post",
  });
};

// 获取系列课程
export const getSeriesCoursePage = (params) => {
  return request({
    url: `/series/getSeriesCoursePage`,
    params,
  });
};

// 获取ai课程
export const getSeriesKnowledgeCourse = (params) => {
  return request({
    url: `/series/getSeriesKnowledgeCourse`,
    params,
  });
};

// 是否关注公众号
export const getUserPublicSubscribe = (params) => {
  return request({
    url: `/user/getUserPublicSubscribe`,
    params,
  });
};

// 获取公众号二维码
export const getQRCode = (params) => {
  return request({
    url: `/weChat/getQRCode`,
    params,
  });
};

// 获取病例
export const getCaseListByRelationId = (params) => {
  return request({
    url: `/case/getCaseListByRelationId`,
    params,
  });
};

// 获取病例列表
export const getCaseList = (params) => {
  return request({
    url: `/case/getCaseListByRelationId`,
    params,
  });
};

// 获取专家列表
export const getExpertListByThemeId = (params) => {
  return request({
    url: `/perTheme/getExpertListByThemeId`,
    params,
  });
};

// 获取首页信息
export const newSimpleThemeColumnListAndBanner = (params) => {
  return request({
    url: `/perTheme/newSimpleThemeColumnListAndBanner/${params.id}`,
    params,
  });
};

// 获取预览信息
export const getPreviewVerify = (params) => {
  return request({
    url: `/perTheme/previewVerify/${params.randomId}`,
    params,
  });
};

// 获取课程完成视频数
export const getCourseFinishVideoNum = (params) => {
  return request({
    url: `/special/getCourseFinishVideoNum`,
    params,
  });
};

// 获取活动列表
export const applyActivity = (data) => {
  return request({
    url: "/activity/activityApply/apply",
    data,
    method: "post",
  });
};

// 获取活动报名信息
export const getUserInfoById = () => {
  return request({
    url: "/user/getUserInfoById",
    method: "get",
  });
};

// 获取学员状态
export const getUserStatus = (params) => {
  return request({
    url: `/user/student/getStatus`,
    params,
  });
};

// 获取某一专栏详情
export const getOneSpecialDetail = (params) => {
  return request({
    url: `/special/querySpecialColumnMini/${params.id}`,
    params,
  });
};

//获取系列课程
export const getClassList = (params) => {
  return request({
    url: `/series/getSeriesCoursePage`,
    params,
  });
};

// 获取系列课程得讲师
export const getClassTeacher = (params) => {
  return request({
    url: `/series/getSeriesExperts`,
    params,
  });
};

// 获取某一tab详情
export const getTabDetail = (params) => {
  return request({
    url: `/special/querySpecialContentMini/${params.id}`,
    params,
  });
};

// 专栏内容搜索
export const searchContent = (params = {}) => {
  return request({
    url: "special/level2/list",
    method: "get",
    params,
  });
};

//获取系列课程观看数量
export const getClassLook = (params) => {
  return request({
    url: `/series/getSeriesCourseViewNum`,
    params,
  });
};

// 获取ai课程
export const getAiClassList = (params) => {
  return request({
    url: `/series/getSeriesKnowledgeCourse`,
    params,
  });
};

// 获取病例详情
export const getCaseDetail = (params) => {
  return request({
    url: `/case/detail`,
    params,
  });
};

// 新增留言
export const publishComment = (data) => {
  return request({
    url: "/comment/add",
    data,
    method: "post",
  });
};
// 留言列表
export const getCommentList = (params) => {
  return request({
    url: `/comment/page/student`,
    params,
  });
};
// 二级留言列表
export const getCommentChildList = (params) => {
  return request({
    url: `/comment/page/student/detail`,
    params,
  });
};

// 提交我的诊断
export const diagnoseCommit = (data) => {
  return request({
    url: "/case/diagnoseCommit",
    data,
    method: "post",
  });
};

// 评论点赞
export const dealCommentLike = (params) => {
  return request({
    url: `/comment/commentLike`,
    params,
  });
};

export const logout = (params = {}) => {
  return request({
    url: "/user/logout",
    method: "get",
    params,
  });
};

// 获取专家医院
export const getExpertHospitalByTagName = (params = {}) => {
  return request({
    url: "/user/getExpertHospitalByTagName",
    method: "get",
    params,
  });
};

// 获取医院列表
export const hospitalList = (params = {}) => {
  return request({
    url: "/user/expert/hospitalList",
    method: "get",
    params,
  });
};

// 获取专家科室
export const listByTagName = (params = {}) => {
  return request({
    url: "/department/listByTagName",
    method: "get",
    params,
  });
};

// 获取专家列表
export const getExpertListByTagName = (params = {}) => {
  return request({
    url: "/user/getExpertListByTagName",
    method: "get",
    params,
  });
};

// 专家列表
export const expertList = (params = {}) => {
  return request({
    url: "/user/expertList",
    method: "get",
    params,
  });
};

// 获取专家详情
export const getExpertDetail = (params = {}) => {
  return request({
    url: "/user/expert/allDetail",
    method: "get",
    params,
  });
};

// 获取云科室团队列表
export const miniCloudList = (params = {}) => {
  return request({
    url: "/user/expertTeam/miniCloudList",
    method: "get",
    params,
  });
};

// 获取云科室团队列表
export const miniList = (params = {}) => {
  return request({
    url: "/user/expertTeam/miniList",
    method: "get",
    params,
  });
};

// 添加咨询
export const addConsult = (data) => {
  return request({
    url: "/consult/add",
    data,
    method: "post",
  });
};

// 获取咨询列表
export const studentConsultList = (params = {}) => {
  return request({
    url: "/consult/page/student",
    method: "get",
    params,
  });
};

// 获取咨询详情
export const studentConsultDetail = (params = {}) => {
  return request({
    url: "/consult/student/detail",
    method: "get",
    params,
  });
};

// 更新咨询状态
export const updateReadStatus = (params = {}) => {
  return request({
    url: "/consult/updateReadStatus",
    method: "get",
    params,
  });
};

// 获取专家团队成员详情
export const expertTeamDetail = (params = {}) => {
  return request({
    url: "/user/expertTeam/detail",
    method: "get",
    params,
  });
};

// 获取专区列表
export const getOneSpecialList = (params = {}) => {
  return request({
    url: "/special/listAndBannerPage" + "/" + params.id,
    method: "get",
    params,
  });
};

// 获取专区二级列表
export const searchSecondActNew = (params = {}) => {
  return request({
    url: "/special/level2/page",
    method: "get",
    params,
  });
};

// 参加项目
export const applyProject = (params) => {
  return request({
    url: "apply/project/student/add",
    method: "get",
    params,
  });
};

export const getApplyStatus = () => {
  return request({
    url: "program/list",
    method: "get",
  });
};

// 获取平台信息
export const getPlatformConfig = (params) => {
  return request({
    url: "/platform/index/config",
    params,
  });
};

export const consultDetail = (params) => {
  return request({
    url: "consult/pc/student/detail",
    method: "get",
    params,
  });
};
