<template>
  <div class="basic-wrapper">
    <Head></Head>
    <section class="content">
      <router-view></router-view>
    </section>
  </div>
</template>
<script>
import Head from "@/components/PC/Head/index.vue";

export default {
  components: {
    Head,
  },
};
</script>
<style lang="less" scoped>
.basic-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex-grow: 1;
    overflow: auto;
    padding-top: 20px;
  }
}
</style>
